/* eslint-disable one-var */
/* eslint-disable one-var-declaration-per-line */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-mixed-operators */
import scrollTo from "jquery-scroll";
import "jquery-mousewheel";
import { isMobileUA } from "mdetect";

import { scrollTop } from "@shared/scripts/utils/tool";

const banner = () => {
  const $window = $(window);

  $(".js-menu-btn").on("click", function() {
    $(this)
      .parent(".menu-list")
      .toggleClass("is-active");
  });

  if (isMobileUA()) return;
  $window.on("mousewheel", scrollDown);
  function scrollDown(e) {
    const direction = e.deltaY < 0 ? "down" : "up";

    if (direction === "down" && scrollTop() <= 100) {
      $window.off("mousewheel", scrollDown);

      scrollTo({
        selector: "#live-scene",
        duration: 400,
        offset: 70,
        callback: () => {
          $window.on("mousewheel", scrollDown);
        },
      });
    }
  }
};

export default banner;

/* global BMap */
import { isMobileView } from "mdetect";
import locationIcon from "gmis2019/icon-location.png";

const address = () => {
  const map = new BMap.Map("js-gmis-map", {
    enableMapClick: false,
  });

  const centerPoint = { x: 121.451248, y: 31.285702 };

  if (isMobileView()) {
    map.disableDragging();
    centerPoint.x = 121.452447;
    centerPoint.y = 31.28621;
  }

  map.centerAndZoom(
    new BMap.Point(centerPoint.x, centerPoint.y),
    isMobileView() ? 16 : 18
  );
  map.setCurrentCity("上海");

  const myIcon = new BMap.Icon(locationIcon, new BMap.Size(44, 50));

  myIcon.setImageSize(new BMap.Size(44, 50));
  const marker = new BMap.Marker(new BMap.Point(121.452447, 31.28621), {
    icon: myIcon,
  });

  map.addOverlay(marker);
  const label = new BMap.Label("上海宝华万豪酒店", {
    offset: new BMap.Size(-62, -50),
  });
  label.setStyle({
    color: "black",
    fontSize: "16px",
    padding: "8px 20px",
    border: "none",
    boxShadow: "1px 0 5px rgba(0, 0, 0, 0.2)",
  });

  marker.setLabel(label);
};

export default address;

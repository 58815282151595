const title = document.title || "机器之心 | 全球人工智能信息服务";
const descMeta = document.querySelector('meta[name="description"]');
const link = document.location.href;

const desc = descMeta ? descMeta.getAttribute("content") : "";

const defaultShare = {
  title,
  desc,
  link,
  imgUrl: "https://image.jiqizhixin.com/synced-share-icon.jpg",
};

const wechat = shareInfo => {
  const share = { ...defaultShare, ...shareInfo };

  wx.config({
    debug: process.env.NODE_ENV === "development",
    ...wxDefaultConfig,
    jsApiList: [
      "onMenuShareTimeline",
      "onMenuShareQQ",
      "onMenuShareWeibo",
      "onMenuShareAppMessage",
      "onMenuShareQZone",
    ],
  });
  wx.ready(() => {
    wx.onMenuShareTimeline(share);
    wx.onMenuShareQQ(share);
    wx.onMenuShareWeibo(share);
    wx.onMenuShareAppMessage(share);
    wx.onMenuShareQZone(share);
  });
};

export default wechat;

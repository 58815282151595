const agenda = () => {
  const $container = $(".agenda-slider-container");
  const $labels = $container.find(".agenda-switch-label");
  const $list = $container.find(".agenda-slider-list").eq(0);
  const $items = $list.find(".agenda-slider-item");

  $labels.find(".agenda-switch-button").on("click", function() {
    const $this = $(this);
    const $label = $this.closest(".agenda-switch-label");
    const index = $label.index();

    $label.siblings().removeClass("is-active");
    $label.addClass("is-active");
    $items.eq(index).addClass("is-active");
    $items
      .eq(index)
      .siblings()
      .removeClass("is-active");
  });
};

export default agenda;

import { isMobileUA, isWechat } from "mdetect";
import "jquery-lazyload";
import "scrollit";

import wechat from "@shared/scripts/utils/wechat";
import header from "./components/header";
import address from "./components/address";
import video from "./components/video";
import banner from "./components/banner";
import agenda from "./components/agenda";
import "./styles/index.scss";

const init = () =>
  $(() => {
    header();
    banner();
    video();
    address();
    agenda();

    $.scrollIt({ topOffset: isMobileUA() ? 0 : -75 });
    $(".js-lazy").lazyload({
      threshold: 200,
      effect: "fadeIn",
    });

    // wechat share
    if (isWechat()) {
      wechat({
        title: "GMIS全球机器智能峰会重磅开启",
        desc: "A Machine That Learns？",
        link: "https://gmis.jiqizhixin.com",
        imgUrl: "https://image.jiqizhixin.com/gmis2017/gmis-share-icon.jpg",
      });
    }
  });

export default init;
